$('input[type=radio]#DocumentSourceId').change(function () {
    //alert($(this).val());
    if ($(this).data('radio-value') !== 'File Upload') {
        $('.hide-details').addClass('hidden');
    } else {
        $('.hide-details').removeClass('hidden');
    }

});

if ($('input[type=radio]#DocumentSourceId[data-radio-value="File Upload"]').parent().hasClass('checked')) {
    $('.hide-details').removeClass('hidden');
}

$(".ui.form.proof")
    .form({
        inline: true,
        on: "blur",
        onFailure: function () {
            const firstFieldError = $(".field.error").first();

            $("body, html").animate({
                scrollTop: firstFieldError.offset().top
            });
            return false;
        },
        fields: {
            DocumentSourceId: {
                identifier: "DocumentSourceId",
                rules: [
                    {
                        type: "checked",
                        prompt: "Please select how do you intend to supply proof of identity and proof of ownership" 
                    }
                ]
            }
        }
    });

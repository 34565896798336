$(".ui.form.corrections")
    .form({
        inline: true,
        on: "blur",
        onFailure: function () {
            const firstFieldError = $(".field.error").first();

            $("body, html").animate({
                scrollTop: firstFieldError.offset().top
            });
            return false;
        },
        fields: {
            V5CorrectionsFieldName: {
                identifier: "V5Corrections[0].FieldName",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please select a field name"
                    }
                ]
            },
            V5CorrectionsCurrentValue: {
                identifier: "V5Corrections[0].CurrentValue",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a current value"
                    },
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            },
            V5CorrectionsNewValue: {
                identifier: "V5Corrections[0].NewValue",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a new value"
                    },
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            }
        }
    });

const countGroupLength = $('.field-group').length;

if (countGroupLength === 1) {
    $('.remove-field-group').hide();
} else {
    $('.remove-field-group').show();
}

$('.add-field-group').on('click', function(a) {
    a.preventDefault();
    const e = $(this).parent().parent().find('.field-group').last();
    e.clone().insertAfter(e);

    const $newGrp = $(this).parent().parent().find('.field-group').last();
    const currentFieldNumber = $newGrp.index();
    const currentFieldNumberLabel = $newGrp.index() + 1;

    $newGrp.attr('id', 'group[' + currentFieldNumber + ']');
    $newGrp.find('.FieldNumber label').html('Field ' + currentFieldNumberLabel + '');
    $newGrp.find('.FieldName .tooltip, .CurrentValue .tooltip, .NewValue .tooltip').hide();
    $newGrp.find('.FieldName select').attr({name: 'V5Corrections[' + currentFieldNumber + '].FieldName', id: 'V5Corrections[' + currentFieldNumber + ']__FieldName'}).val('');
    $newGrp.find('.FieldName label').attr({ for: 'V5Corrections[' + currentFieldNumber + '].FieldName' });
    $newGrp.find('.CurrentValue input').attr({ name: 'V5Corrections[' + currentFieldNumber + '].CurrentValue', id: 'V5Corrections[' + currentFieldNumber + ']__CurrentValue'}).val('');
    $newGrp.find('.CurrentValue label').attr({ for: 'V5Corrections[' + currentFieldNumber + '].CurrentValue' });
    $newGrp.find('.NewValue input').attr({ name: 'V5Corrections[' + currentFieldNumber + '].NewValue', id: 'V5Corrections[' + currentFieldNumber + ']__NewValue'}).val('');
    $newGrp.find('.NewValue label').attr({ for: 'V5Corrections[' + currentFieldNumber + '].NewValue' });

    const validFields = {};
    const countGroupLength = $('.field-group').length;

    if (countGroupLength > 1) {
        $(this).show();
        $('.remove-field-group').show();
    }

    if (countGroupLength === 5) {
        $(this).hide();
    }

    //console.log(countGroupLength);
    for (let i = 0; i < countGroupLength; i++) {
        validFields["V5Corrections[" + (i) + "].FieldName"] = {
                    identifier: "V5Corrections[" + (i) + "].FieldName",
                        rules:
                    [
                        {
                            type: "empty",
                            prompt: "Please select a field name"
                        }
                    ]
                };
        validFields["V5Corrections[" + (i) + "].CurrentValue"] = {
                    identifier: "V5Corrections[" + (i) + "].CurrentValue",
                        rules:
                    [
                        {
                            type: "empty",
                            prompt: "Please enter a current value"
                        },
                        {
                            type: "maxLength[50]",
                            prompt: "You can only enter up to 50 characters"
                        }
                    ]
                };
        validFields["V5Corrections[" + (i) + "].NewValue"] = {
                    identifier: "V5Corrections[" + (i) + "].NewValue",
                        rules:
                    [
                        {
                            type: "empty",
                            prompt: "Please enter a new value"
                        },
                        {
                            type: "maxLength[50]",
                            prompt: "You can only enter up to 50 characters"
                        }
                    ]
                };
    }
      //console.log(validFields);      

    $(".ui.form.corrections")
        .form({
            inline: true,
            on: "blur",
            onFailure: function () {
                const firstFieldError = $(".field.error").first();

                $("body, html").animate({
                    scrollTop: firstFieldError.offset().top
                });
                return false;
            },
            fields: validFields
        });

    $('.dropdown').dropdown({
        placeholder: false
    });
});

$('.remove-field-group').on('click', function (a) {
    a.preventDefault();
    const e = $(this).parent().parent().find('.field-group').last();
    e.remove();

    const validFields = {};
    const countGroupLength = $('.field-group').length;

    if (countGroupLength === 1) {
        $(this).hide();
    }

    if (countGroupLength <= 5) {
        $('.add-field-group').show();
    }

    //console.log(countGroupLength);
    for (let i = 0; i < countGroupLength; i++) {
        validFields["V5Corrections[" + (i) + "].FieldName"] = {
            identifier: "V5Corrections[" + (i) + "].FieldName",
            rules:
            [
                {
                    type: "empty",
                    prompt: "Please select a field name"
                }
            ]
        };
        validFields["V5Corrections[" + (i) + "].CurrentValue"] = {
            identifier: "V5Corrections[" + (i) + "].CurrentValue",
            rules:
            [
                {
                    type: "empty",
                    prompt: "Please enter a current value"
                },
                {
                    type: "maxLength[50]",
                    prompt: "You can only enter up to 50 characters"
                }
            ]
        };
        validFields["V5Corrections[" + (i) + "].NewValue"] = {
            identifier: "V5Corrections[" + (i) + "].NewValue",
            rules:
            [
                {
                    type: "empty",
                    prompt: "Please enter a new value"
                },
                {
                    type: "maxLength[50]",
                    prompt: "You can only enter up to 50 characters"
                }
            ]
        };
    }
    //console.log(validFields);      

    $(".ui.form.corrections")
        .form({
            inline: true,
            on: "blur",
            onFailure: function () {
                const firstFieldError = $(".field.error").first();

                $("body, html").animate({
                    scrollTop: firstFieldError.offset().top
                });
                return false;
            },
            fields: validFields
        });
});





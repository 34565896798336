class preRequisite {
    private radioCarVan;
    private radioRequestType;
    private radioModifications;
    private buttonStartOver;
    private changeValue;
    private selectedValue;
    private buttonModificationsNext;

    constructor() {
        this.radioCarVan = $('.pre-requisite .car-van input[type=radio]').click(this.radioCarVanClick);
        this.radioRequestType = $('.pre-requisite .request-type input[type=radio]').click(this.radioRequestTypeClick);
        this.radioModifications = $('.pre-requisite .modifications input[type=radio]').click(this.radioModificationsClick);
        this.buttonModificationsNext = $('#modifications-next').click(this.modificationsNextClick);
        this.buttonStartOver = $('.start').click(this.buttonStartOverClick);
        this.changeValue = $('.dropdown.dark')
            .dropdown({
                onChange: function (value, text, $selectedItem) {
                    if ($selectedItem.val() !== 'Please Select') {
                        $('.proceed').prop('disabled', false);
                    }
                }
            });
        const selectedValue = $('#CountryId').find('option:eq(0)').prop('selected', true);
        if (selectedValue) {
            $('.proceed').prop('disabled', true);
        }

    }

    public radioCarVanClick() {
        if ($(this).val() === 'car' || $(this).val() === 'van') {
            $(this).closest('.steps').hide();
            $(this).closest('.steps').next('.steps').show();
        }
    }

    public radioRequestTypeClick() {
        if ($(this).data("req-type") === 'import' || $(this).data("req-type") === 'export') {
            $(this).closest('.steps').hide();
            $(this).closest('.steps').next('.steps').show();
        }
        if ($(this).data("req-type") === 'import') {
            $('span.importing-from').show();
        } else if ($(this).data("req-type") === 'export') {
            $('span.exporting-to').show();
        } else {
            $(this).closest("form").submit();
        }
    }

    public radioModificationsClick() {
        const $modificationsContainer = $("#modifications-container");
        if ($(this).val() === "true") {
            $modificationsContainer.show();
        }
        else {
            $modificationsContainer.hide();
            $modificationsContainer.find("textarea").val("");
        }

        //if ($(this).val() === 'true' || $(this).val() === 'false') {
        //    $(this).closest('.steps').hide();
        //    $(this).closest('.steps').next('.steps').show();
        //}
    }
    public modificationsNextClick() {
        $(this).closest('.steps').hide();
        $(this).closest('.steps').next('.steps').show();
    }
    public buttonStartOverClick() {
        $('.steps').hide();
        $('.pre-requisite p span').hide();
        $('.steps:first-child').show();
        $('input[type=radio]').prop('checked', false);
        $('.dropdown.dark').dropdown('restore defaults');
        $('.proceed').prop('disabled', true);
    }
}

new preRequisite();


class MyCheckBox {

    private checkboxEl: JQuery;

    constructor(selector: string) {
        this.checkboxEl = $(selector);
    }

    public initialise(): void {
        this.checkboxEl.checkbox();
    }

    isSelected() { this.checkboxEl.checkbox('is checked'); }
}

const singleCheckbox = new MyCheckBox('.ui.checkbox');
singleCheckbox.initialise();
singleCheckbox.isSelected();

//Custom checkboxes
$('.options-selector input[type=radio]').each(function() {
    if ($(this).is(':checked')) {
        $(this).parent('.option').addClass('checked');
    }
});

$('.billing input[type=radio]').on('change', function() {
    if ($(this).val() === "yes") {
        $('.billing .enable-disable .field').addClass('disabled');
    } else {
        $('.billing .enable-disable .field').removeClass('disabled');
    }
});

$('.options-selector.enable-disable-radio input[type=radio]').on('change', function () {
    if ($(this).val() === "yes") {
        $(this).closest('.enable-disable').nextAll('.enable-disable').find('.field').removeClass('disabled');
    } else {
        $(this).closest('.enable-disable').nextAll('.enable-disable').find('.field').addClass('disabled');
    }
});
  
//$('.ui.checkbox').checkbox();
/* eslint-disable @typescript-eslint/ban-ts-comment */
// function to check if at least one text is not empty for a collection of elements
// text is the value of the input device
// csv is the argument as string. It's the string inside "[" and "]"
$.fn.form.settings.rules.allHaveValues = function (text, csv) {
    //If the text of the field itself isn't empty, then it is valid
    if (text)
        return true;
    const array = csv.split(','); // you're separating the string by commas
    let allHaveValues = true; // return value

    $.each(array, function (index, elem) {
        // for each item in array, get an input element with the specified name, and check if it has any values
        const element = $("input[id='" + elem + "']");
        //If element is found, and it's value is not empty, then it is valid
        if (!(element && element.val())) {
            allHaveValues = false;
            return;
        }
    });
    return allHaveValues;
};

$(".ui.form.customer_details")
    .form({        
        on: "blur",
        inline: true,       
        onFailure: function () {
            const firstFieldError = $(".field.error").first();

            $("body, html").animate({
                scrollTop: firstFieldError.offset().top
            });
            return false;
        },         
        // @ts-expect-error: TS incorrectly highlights this is an error
        fields: {
            BillingAddress_Title: {
                identifier: "BillingAddress_Title",
                rules: [
                    {
                        type: "allHaveValues[BillingAddress_CompanyName]",
                        prompt: "Please enter a Title"
                    }
                ]
            },
            BillingAddress_FirstName: {
                identifier: "BillingAddress_FirstName",
                rules: [
                    {
                        type: "allHaveValues[BillingAddress_CompanyName]",
                        prompt: "Please enter a First Name"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_Surname: {
                identifier: "BillingAddress_Surname",
                rules: [
                    {
                        type: "allHaveValues[BillingAddress_CompanyName]",
                        prompt: "Please enter a Surname"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_CompanyName: {
                identifier: "BillingAddress_CompanyName",
                rules: [
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            },
            Telephone: {
                identifier: "Telephone",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a telephone number"
                    },
                    {
                        type: "regExp[/^[0-9 /(/)/+/-]*$/]",
                        prompt: "Please enter telephone number in the correct format"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            Email: {
                identifier: "Email",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter an email"
                    },
                    {
                        type: "email",
                        prompt: "Please enter a valid email"
                    },
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            },
            BillingAddress_Address1: {
                identifier: "BillingAddress_Address1",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter an address"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_Address2: {
                identifier: "BillingAddress_Address2",
                optional: true,
                rules: [
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_Address3: {
                identifier: "BillingAddress_Address3",
                optional: true,
                rules: [
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_Town: {
                identifier: "BillingAddress_Town",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a town"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_County: {
                identifier: "BillingAddress_County",
                optional: true,
                rules: [
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            BillingAddress_Postcode: {
                identifier: "BillingAddress_Postcode",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a postcode"
                    },
                    {
                        type: "regExp[^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$]",
                        prompt: "Please enter a valid postcode"
                    },
                    {
                        type: "maxLength[8]",
                        prompt: "You can only enter up to 8 characters"
                    }
                ]
            },
            BillingAddress_Country_Id: {
                identifier: "BillingAddress_Country_Id",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please select a country"
                    }
                ]
            },
            UseDifferentAddress: {
                identifier: "UseDifferentAddress",
                optional: true,
                rules: [
                    {
                        type: "empty"
                    }
                ]
            },
            DeliveryAddress_Title: {
                identifier: "DeliveryAddress_Title",
                depends: "UseDifferentAddress",
                rules: [
                    {
                        type: "allHaveValues[DeliveryAddress_CompanyName]",
                        prompt: "Please choose a title"
                    }
                ]
            },
            DeliveryAddress_FirstName: {
                identifier: "DeliveryAddress_FirstName",
                depends: "UseDifferentAddress",
                rules: [
                    {
                        type: "allHaveValues[DeliveryAddress_CompanyName]",
                        prompt: "Please enter a name"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_Surname: {
                identifier: "DeliveryAddress_Surname",
                depends: "UseDifferentAddress",
                rules: [
                    {
                        type: "allHaveValues[DeliveryAddress_CompanyName]",
                        prompt: "Please enter a surname"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_CompanyName: {
                identifier: "DeliveryAddress_CompanyName",
                depends: "UseDifferentAddress",
                optional: true,
                rules: [
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            },
            DeliveryAddress_Address1: {
                identifier: "DeliveryAddress_Address1",
                depends: "UseDifferentAddress",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter an address"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_Address2: {
                identifier: "DeliveryAddress_Address2",
                depends: "UseDifferentAddress",
                optional: true,
                rules: [
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_Address3: {
                identifier: "DeliveryAddress_Address3",
                depends: "UseDifferentAddress",
                optional: true,
                rules: [
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_Town: {
                identifier: "DeliveryAddress_Town",
                depends: "UseDifferentAddress",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a town"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_County: {
                identifier: "DeliveryAddress_County",
                depends: "UseDifferentAddress",
                optional: true,
                rules: [
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    },
                    {
                        type: "maxLength[30]",
                        prompt: "You can only enter up to 30 characters"
                    }
                ]
            },
            DeliveryAddress_Postcode: {
                identifier: "DeliveryAddress_Postcode",
                depends: "UseDifferentAddress",
                optional: true,
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a postcode"
                    },
                    {
                        type: "regExp[^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$]",
                        prompt: "Please enter a valid postcode"
                    },
                    {
                        type: "maxLength[8]",
                        prompt: "You can only enter up to 8 characters"
                    }
                ]
            },
            DeliveryAddress_Country_Id: {
                identifier: "DeliveryAddress_Country_Id",
                depends: "UseDifferentAddress",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please select a country"
                    }
                ]
            }
        }
    });



$(".different-address.checkbox")
    .checkbox({
        onChange: function () {
            $(".hide-details").toggleClass("hidden");
        }

    });

$(document).ready(function () {
    $(".max-char-countdown").keyup(function (e) {
        var maxLength = parseInt($(this).attr("maxlength")) || 1024;
        var currentvalue = $(this).val().toString();

        var length = currentvalue.length;
        var remainingChars = $(this).next(".remaining-chars");

        remainingChars.text(maxLength - length);

        if (length === maxLength) {
            e.preventDefault();
        } else if (length > maxLength) {
            // Maximum exceeded
            $(this).val(currentvalue.substring(0, maxLength));
            remainingChars.text(length);
        }
    });
});
$('input[name="daterange"]').daterangepicker(
    {
        locale: {
            format: 'DD-MM-YYYY'
        },
        showDropdowns: false
        //buttonClasses: 'ui button primary full-width',
        //cancelClass: 'secondary'
    },
    function (start, end, label) {
        
        // this is a bit hacky, but it works for now.
        const startDate = new Date(Date.parse(start.toLocaleString()));
        const endDate = new Date(Date.parse(end.toLocaleString()));

        $("#FilterStartDate").val(startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear());
        $("#FilterEndDate").val(endDate.getDate() + "/" + (endDate.getMonth() + 1) + "/" + endDate.getFullYear());
    }
).on('cancel.daterangepicker', function (ev, picker) {
    //do something, like clearing an input
    $('input[name = "daterange"]').val("");
    const $filterStartDate = $("#FilterStartDate");
    const $filterEndDate = $("#FilterEndDate");
    $filterStartDate.val("");
    $filterEndDate.val("");
    });

//AJS: What about when clicking on the icon-calendar "button"?
$('input[name="Vehicle.DateFirstReg"]').daterangepicker(
    {
        locale: {
            format: 'DD-MM-YYYY'
        },
        showDropdowns: true,
        singleDatePicker: true,
        autoUpdateInput: false,
        minDate: new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
        maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    }, function (chosenDate) {
        const thisDate = new Date(Date.parse(chosenDate.toLocaleString()));
        $('input[name="Vehicle.DateFirstReg"]').val(thisDate.getDate() + "/" + (thisDate.getMonth() + 1) + "/" + thisDate.getFullYear());
    }
).on("show.daterangepicker", function (ev, picker) {
    const $input = $("#" + ev.currentTarget.id);
    const currentVal = $input.val();
    if (currentVal === "") {
        const startDate = new Date(Date.parse(picker.startDate.toLocaleString()));
        $input.val(startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear());
    }
});

$('input[name="DateOfManufacture"]').daterangepicker({
    locale: {
        format: 'DD-MM-YYYY'
    },
    showDropdowns: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    minDate: new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
    maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
}, function (chosenDate) {
    const thisDate = new Date(Date.parse(chosenDate.toLocaleString()));
    $('input[name="DateOfManufacture"]').val(thisDate.getDate() + "/" + (thisDate.getMonth() + 1) + "/" + thisDate.getFullYear());
}).on("show.daterangepicker", function (ev, picker) {
    const $input = $("#" + ev.currentTarget.id);
    const currentVal = $input.val();
    if (currentVal === "") {
        const startDate = new Date(Date.parse(picker.startDate.toLocaleString()));
        $input.val(startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear());
    }
    });

//MJ: Can we get this to work on a class or attribute rather than specifying each control?
$('input[name="FactoryPaymentDate"]').daterangepicker({
    locale: {
        format: 'DD-MM-YYYY'
    },
    showDropdowns: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    minDate: new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
    maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
}, function (chosenDate) {
    const thisDate = new Date(Date.parse(chosenDate.toLocaleString()));
    $('input[name="FactoryPaymentDate"]').val(thisDate.getDate() + "/" + (thisDate.getMonth() + 1) + "/" + thisDate.getFullYear());
}).on("show.daterangepicker", function (ev, picker) {
    const $input = $("#" + ev.currentTarget.id);
    const currentVal = $input.val();
    if (currentVal === "") {
        const startDate = new Date(Date.parse(picker.startDate.toLocaleString()));
        $input.val(startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear());
    }
    });

$('input[name="ReconcilliationDate"]').daterangepicker({
    locale: {
        format: 'DD-MM-YYYY'
    },
    showDropdowns: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    minDate: new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
    maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
}, function (chosenDate) {
    const thisDate = new Date(Date.parse(chosenDate.toLocaleString()));
    $('input[name="ReconcilliationDate"]').val(thisDate.getDate() + "/" + (thisDate.getMonth() + 1) + "/" + thisDate.getFullYear());
}).on("show.daterangepicker", function (ev, picker) {
    const $input = $("#" + ev.currentTarget.id);
    const currentVal = $input.val();
    if (currentVal === "") {
        const startDate = new Date(Date.parse(picker.startDate.toLocaleString()));
        $input.val(startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear());
    }
    });

$(".icon-calendar").click(function () {
    $(this).parent().find('input[type=text]').click();
});

/* eslint-disable no-useless-escape */
$(".ui.form.vehicle_details")
    .form({
        inline: true,
        on: "blur",
        onFailure: function () {
            const firstFieldError = $(".field.error").first();

            $("body, html").animate({
                scrollTop: firstFieldError.offset().top
            });
            return false;
        },
        fields: {
            Vehicle_Make: {
                identifier: "Vehicle_Make",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please select a vehicle"
                    }
                ]
            },
            Vehicle_Model: {
                identifier: "Vehicle_Model",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a model"
                    },
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            },
            Vehicle_VIN: {
                identifier: "Vehicle_VIN",
                rules: [
                    {
                        type: "minLength[14]",
                        prompt: "Please enter a minumum of 14 characters"
                    },
                    {
                        type: "maxLength[17]",
                        prompt: "Please enter up to 17 characters"
                    }
                ]
            },
            Vehicle_EngineNumber: {
                identifier: "Vehicle_EngineNumber",
                optional: true,
                rules: [
                    {
                        type: "minLength[14]",
                        prompt: "Please enter 14 characters"
                    },
                    {
                        type: "maxLength[14]",
                        prompt: "You can only enter up to 14 characters"
                    }
                ]
            },
            Vehicle_CurrentRegistrationCountry_Id: {
                identifier: "Vehicle_CurrentRegistrationCountry_Id", 
                rules: [
                    {
                        type: "empty",
                        prompt: "Please select a Country"
                    }
                ]
            },
            Vehicle_CurrentRegistrationNumber: {
                identifier: "Vehicle_CurrentRegistrationNumber",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a registration number"
                    },
                    {
                        type: "maxLength[10]",
                        prompt: "You can only enter up to 10 characters"
                    }
                ]
            },
            Vehicle_DateFirstReg: {
                identifier: "Vehicle_DateFirstReg",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a date"
                    },
                    {
                        type: "regExp[/^(0?[1-9]|[12][0-9]|3[01])[\/.](0?[1-9]|1[012])[\/.](19|20)\\d\\d$/]",
                        prompt: "Please enter a valid dd/mm/yyyy date"
                    }
                ]
            },
            Vehicle_SteeringWheelSide: {
                identifier: "Vehicle_SteeringWheelSide",
                rules: [
                    {
                        type: "checked",
                        prompt: "Please select a Vehicle configuration"
                    }
                ]
            },
            Vehicle_hasBeenModified: {
                identifier: "Vehicle_hasBeenModified",
                rules: [
                    {
                        type: "checked",
                        prompt: "Please select whether your vehicle has been modified"
                    }
                ]
            }
            //Vehicle_hasBeenModifiedDetails: {
            //    identifier: "Vehicle_hasBeenModifiedDetails",
            //    depends: 'Vehicle_hasBeenModifiedYes',
            //    rules: [
            //        {
            //            type: "empty",
            //            prompt: "Please enter vehicle modification details"
            //        }
            //    ]
            //}
        }
    });

$('input:radio[name="Vehicle_hasBeenModified"]').change(
    function () {
        if ($(this).is(':checked') && $(this).val() === 'Yes') {
            $('.hidden').show();
        } else {
            $('.hidden').hide();
        }
    });
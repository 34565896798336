/* eslint-disable no-useless-escape */
$(".ui.form.corrections_vehicle_details")
    .form({
        inline: true,
        on: "blur",
        onFailure: function () {
            const firstFieldError = $(".field.error").first();

            $("body, html").animate({
                scrollTop: firstFieldError.offset().top
            });
            return false;
        },
        fields: {
            Vehicle_Model: {
                identifier: "Vehicle_Model",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a model"
                    },
                    {
                        type: "maxLength[50]",
                        prompt: "You can only enter up to 50 characters"
                    }
                ]
            },
            Vehicle_VIN: {
                identifier: "Vehicle_VIN",
                rules: [
                    {
                        type: "minLength[14]",
                        prompt: "Please enter a minumum of 14 characters"
                    },
                    {
                        type: "maxLength[17]",
                        prompt: "Please enter up to 17 characters"
                    }
                ]
            },
            Vehicle_CurrentRegistrationNumber: {
                identifier: "Vehicle_CurrentRegistrationNumber",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a registration number"
                    },
                    {
                        type: "maxLength[10]",
                        prompt: "You can only enter up to 10 characters"
                    }
                ]
            },
            Vehicle_DateFirstReg: {
                identifier: "Vehicle_DateFirstReg",
                rules: [
                    {
                        type: "empty",
                        prompt: "Please enter a date"
                    },
                    {
                        type: "regExp[/^(0?[1-9]|[12][0-9]|3[01])[\/.](0?[1-9]|1[012])[\/.](19|20)\\d\\d$/]",
                        prompt: "Please enter a valid dd/mm/yyyy date"
                    }
                ]
            }
        }
    });